import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { SignatureRequest } from '@components';
import { CreateProposalForm } from '@components/CreateProposal/CreateProposalForm';
import DAOInfoComponent from '@components/DAOInfo/DAOInfo';
import Header from '@components/Header/Header';
import { ListProposals } from '@components/ListProposals/ListProposals';
import Modal from '@components/modal/modal';
import NFTList from '@components/NFTs/NFTList';
import UserList from '@components/UsersList/UsersList';
import { useAuthContext } from '@shared/next/auth';
import { ConnectWallet, useAddress, useConnectionStatus, useDisconnect } from '@thirdweb-dev/react';

export const GET_DAO_INFO = gql`
  query GetDAOInfo {
    daoInfo {
      id
      title
      description
      logo
      discord
      telegram
      twitter
      extraLink
    }
  }
`;

const GET_PROPOSALS = gql`
  query GetProposals($status: ProposalStatus) {
    proposals(status: $status) {
      id
      title
      description
      status
      createdAt
      winningVoteOptionId
      minimumApprovalPercentage
      quorumPercentage
      durationInDays
      media {
        id
        filename
        originalName
        mimeType
        path
      }
      author {
        walletAddress
      }
      voteOptions {
        id
        title
        description
      }
    }
  }
`;

const Index = () => {
  const { data: daoData, loading: daoLoading, error: daoError } = useQuery(GET_DAO_INFO);

  const connectionStatus = useConnectionStatus();
  const dc = useDisconnect();
  const address = useAddress();

  const { clearAuthContext, authWalletAddress, hasAuthContext, authIsAdmin, authUserStatus } = useAuthContext();

  const [isBannedModalOpen, setIsBannedModalOpen] = useState(false); // State for Banned Modal

  useEffect(() => {
    if (authUserStatus === 'BANNED') {
      setIsBannedModalOpen(true);
    } else {
      setIsBannedModalOpen(false);
    }
  }, [authUserStatus]);

  useEffect(() => {
    if (authUserStatus === '') {
      clearAuthContext();
      dc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUserStatus]);

  useEffect(() => {
    if (connectionStatus === 'connected' && hasAuthContext && address !== authWalletAddress) {
      clearAuthContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (connectionStatus === 'disconnected') {
      clearAuthContext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus]);

  const [activeTab, setActiveTab] = useState<'create' | 'members' | 'nfts'>('nfts');

  const [filterStatus, setFilterStatus] = useState('IN_PROGRESS');

  const { data, loading, error, refetch } = useQuery(GET_PROPOSALS, {
    variables: { status: filterStatus },
  });

  useEffect(() => {
    if (authUserStatus === 'BANNED') {
      setIsBannedModalOpen(true);
    } else {
      setIsBannedModalOpen(false);
    }
  }, [authUserStatus]);

  return (
    <>
      <header className="bg-gray-800 p-4 text-white">
        <Header data={daoData} loading={daoLoading} error={daoError}></Header>
        <ConnectWallet
          btnTitle="Connect"
          detailsBtn={() => {
            return <button className="wallet-media rounded px-4 py-2 font-bold text-white">Connected</button>;
          }}
          className="wallet-media"
        />
      </header>
      <div className="m-4 mx-auto min-h-[80vh] w-[98%] xl:w-11/12">
        <DAOInfoComponent />
        <div className="mx-auto ">
          {!hasAuthContext ? (
            <div className="mt-2 flex flex-col md:flex-row">
              {address && <Modal child={<SignatureRequest address={address} />} />}
              <div className="flex-1 space-y-4 pr-0 md:pr-4">
                <ListProposals
                  data={data}
                  loading={loading}
                  error={error}
                  refetch={refetch}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                />
              </div>
              <div className="mt-4 w-full flex-initial md:mt-0 md:w-1/3">
                <h1 className="ml-2 pb-5 pt-8 text-xs font-bold">Members</h1>
                <UserList />
              </div>
            </div>
          ) : (
            <div className="mt-2 flex flex-col md:flex-row">
              <div className="flex-1 space-y-4 pr-0 md:pr-4">
                <ListProposals
                  data={data}
                  loading={loading}
                  error={error}
                  refetch={refetch}
                  filterStatus={filterStatus}
                  setFilterStatus={setFilterStatus}
                />
              </div>
              <div className="mt-6 w-full flex-initial md:w-1/3">
                <div className="flex flex-wrap justify-around text-xs">
                  <button
                    onClick={() => setActiveTab('nfts')}
                    className={`my-1 mb-2 w-full rounded px-2 py-1 md:mb-0 xl:w-auto ${
                      activeTab === 'nfts' ? 'bg-blue-500' : 'bg-gray-500'
                    } text-white`}
                  >
                    My PFPs
                  </button>
                  <button
                    onClick={() => setActiveTab('members')}
                    className={`my-1 mb-2 w-full rounded px-2 py-1 md:mb-0 xl:w-auto ${
                      activeTab === 'members' ? 'bg-blue-500' : 'bg-gray-500'
                    } text-white`}
                  >
                    Members
                  </button>
                  {authIsAdmin === 'admin' && (
                    <button
                      onClick={() => setActiveTab('create')}
                      className={`my-1 mb-2 w-full rounded px-2 py-1 md:mb-0 xl:w-auto ${
                        activeTab === 'create' ? 'bg-blue-500' : 'bg-gray-500'
                      } text-white`}
                    >
                      New Proposal
                    </button>
                  )}
                </div>
                <div className="mt-5">
                  {activeTab === 'create' && authIsAdmin === 'admin' && (
                    <CreateProposalForm refetchProposals={refetch} />
                  )}
                  {activeTab === 'members' && <UserList />}
                  {activeTab === 'nfts' && address && <NFTList activeDelegation={true} walletAddress={address} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Banned Modal */}
      {isBannedModalOpen && (
        <Modal
          child={
            <div className="rounded bg-gray-800 p-6 text-orange-500 shadow-lg">
              <h2 className="mb-4 text-xl font-bold">You Are Banned</h2>
              <p className="mb-4">
                You have been banned due to accumulating at least three penalties for voting with an NFT and later
                listing it on a marketplace.
              </p>
              <p className="mb-4">Please contact the DAO administrators for more information.</p>
              <button
                onClick={() => setIsBannedModalOpen(false)}
                className="mt-4 rounded bg-gray-500 px-4 py-2 text-white"
              >
                Close
              </button>
            </div>
          }
        ></Modal>
      )}
    </>
  );
};

export default Index;
