import { gql } from '@apollo/client';

export const GET_PROPOSAL = gql`
  query GetProposal($id: ID!) {
    proposal(id: $id) {
      id
      title
      description
      status
      createdAt
      minimumApprovalPercentage
      quorumPercentage
      winningVoteOptionId
      durationInDays
      media {
        id
        filename
        originalName
        mimeType
        path
      }
      author {
        walletAddress
      }
      voteOptions {
        id
        title
        description
      }
      votes {
        id
        member {
          walletAddress
        }
        voteOption {
          id
          title
        }
        nfts {
          id
          tokenID
        }
      }
    }
  }
`;

export const VOTE_MUTATION = gql`
  mutation vote($input: VoteInput!) {
    vote(input: $input) {
      id
      invalidTokenIds
    }
  }
`;
