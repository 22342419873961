import React, { useState } from 'react';

import { ClipLoader } from 'react-spinners';
import { toast, ToastContainer } from 'react-toastify';

import { useLazyQuery, useMutation } from '@apollo/client';
import VoteModal from '@components/modal/VoteModal';
import { useAddress } from '@thirdweb-dev/react';

import { GET_PROPOSAL, VOTE_MUTATION } from './graphqlQueries';
import ProposalItem from './ProposalItem';

import 'react-toastify/dist/ReactToastify.css';

export const ListProposals = ({ data, loading, error, refetch, filterStatus, setFilterStatus }) => {
  const address = useAddress();
  const [expandedId, setExpandedId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentProposal, setCurrentProposal] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [expandedOptionId, setExpandedOptionId] = useState(null);

  const [loadProposal, { loading: proposalLoading, data: proposalData, refetch: refetchProposal }] =
    useLazyQuery(GET_PROPOSAL);
  const [vote, { loading: voteLoading, error: voteError }] = useMutation(VOTE_MUTATION);

  const toggleExpand = async (id) => {
    if (expandedId !== id) {
      setExpandedId(id);
      loadProposal({ variables: { id } });
    } else {
      setExpandedId(null);
    }
  };

  const toggleOptionExpand = (optionId) => {
    if (expandedOptionId !== optionId) {
      setExpandedOptionId(optionId);
    } else {
      setExpandedOptionId(null);
    }
  };

  const handleVoteClick = (proposal, option) => {
    setCurrentProposal(proposal);
    setSelectedOption(option);
    setModalOpen(true);
  };

  const handleConfirmVote = async (tokenIds: string[]) => {
    try {
      if (tokenIds.length === 0) {
        toast.info('Please select at least one NFT to vote');
      } else {
        const response = await vote({
          variables: {
            input: {
              proposalId: currentProposal?.id,
              voteOptionId: selectedOption?.id,
              walletAddress: address,
              tokenIds,
            },
          },
        });

        if (response.errors) {
          const errorMessage = response.errors[0]?.message || 'Error submitting vote';
          toast.error(errorMessage);
        } else {
          toast.success('Vote submitted successfully!');
          // Refrescar los datos de la propuesta actual
          refetchProposal({ id: currentProposal?.id });
        }
        setModalOpen(false);
      }
    } catch (err) {
      toast.error('Error submitting vote: ' + err.message);
    }
  };

  if (loading)
    return (
      <div className="flex justify-center py-4">
        <ClipLoader color="#ffffff" />
      </div>
    );
  if (error) return <p className="p-4 text-red-500">Error: {error.message}</p>;

  return (
    <div className="mx-auto py-6">
      {/* Filtro de estado */}
      <div className="mb-3 flex items-center justify-between">
        <label htmlFor="statusFilter" className="ml-2 mr-2 text-xs text-white">
          Created Proposals
        </label>
        <select
          id="statusFilter"
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="appearance-none rounded border-gray-600 bg-gray-800 p-2 text-xs text-white"
          style={{
            backgroundImage: `url('data:image/svg+xml;utf8,<svg fill="white" height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>')`,
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: 'calc(100% - 0.75rem)',
            backgroundPositionY: 'center',
          }}
        >
          <option value="IN_PROGRESS" className="bg-gray-800 text-white">
            In Progress
          </option>
          <option value="CLOSED" className="bg-gray-800 text-white">
            Closed
          </option>
        </select>
      </div>
      {/* Lista de propuestas */}
      {data.proposals.length === 0 ? (
        <p className="text-center text-gray-300">No proposals found.</p>
      ) : (
        <ul className="space-y-4 text-xs">
          {data.proposals.map((proposal) => (
            <ProposalItem
              key={proposal.id}
              proposal={proposal}
              expandedId={expandedId}
              toggleExpand={toggleExpand}
              address={address}
              handleVoteClick={handleVoteClick}
              expandedOptionId={expandedOptionId}
              toggleOptionExpand={toggleOptionExpand}
              proposalData={proposalData}
              proposalLoading={proposalLoading}
              refetchProposal={refetchProposal}
            />
          ))}
        </ul>
      )}
      {/* Modal de votación */}
      {modalOpen && currentProposal && selectedOption && address && (
        <VoteModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          proposal={proposalData?.proposal}
          selectedOption={selectedOption}
          onConfirm={handleConfirmVote}
          walletAddress={address}
        />
      )}
      <ToastContainer theme="dark" />
    </div>
  );
};
