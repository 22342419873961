import React, { useEffect, useState } from 'react';

import { addDays, formatDistanceToNow, parseISO, subDays } from 'date-fns';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';

import { useQuery } from '@apollo/client';
import TextWithLinks from '@components/ListProposals/TextWithLinks';
import { GET_OWNED_AND_DELEGATED_NFTS } from '@components/NFTs/NFTList';

import { Proposal, VoteOption } from '@.generated/model';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  proposal: Proposal;
  selectedOption: VoteOption;
  onConfirm: (ids: string[]) => Promise<void>;
  walletAddress: string;
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  tokenIds: string[];
  votePower: number;
  selectedNFTs: any[];
  onConfirm: () => Promise<void>;
  handleCheckboxChange: (tokenId: string) => void;
  loading: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  tokenIds,
  votePower,
  selectedNFTs,
  onConfirm,
  handleCheckboxChange,
  loading,
}) => {
  const formatAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70">
      <div className="relative max-h-[95vh] w-10/12 overflow-y-auto rounded bg-gray-900 p-5 shadow-lg md:w-5/12">
        <div className="flex items-center justify-between">
          <h3 className="text-base font-bold text-white">Confirm Your Vote</h3>
          <button className="text-lg text-white" onClick={onClose} disabled={loading}>
            &times;
          </button>
        </div>
        <div className="mt-4">
          <p className="text-gray-300">You are about to vote with the following NFTs:</p>
          <ul className="mt-2 max-h-48 space-y-2 overflow-y-auto p-2 text-xs">
            {selectedNFTs.map((nft: any) => (
              <li key={nft.tokenID} className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <img
                    src={`/pfps/${nft.tokenID}.png`}
                    alt={`NFT ${nft.tokenID}`}
                    className="h-8 w-8 rounded-full"
                    onError={(e) => (e.currentTarget.src = '/default.svg')}
                  />
                  <div>
                    <p className="text-gray-300">Punk Armada # {nft.tokenID}</p>
                    {nft.delegations.some((d: { isActive: any }) => d.isActive) && (
                      <p className="text-green-400">
                        Delegated from:{' '}
                        <a
                          href={`https://etherscan.io/address/${
                            nft.delegations.find((d: { isActive: any }) => d.isActive).delegator.walletAddress
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-400"
                        >
                          {formatAddress(
                            nft.delegations.find((d: { isActive: any }) => d.isActive).delegator.walletAddress,
                          )}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
                <input
                  type="checkbox"
                  checked={tokenIds.includes(nft.tokenID)}
                  onChange={() => handleCheckboxChange(nft.tokenID)}
                  className="ml-auto mr-4"
                />
              </li>
            ))}
          </ul>
          <p className="mt-4 text-gray-300">Total Voting Power: {votePower}</p>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="rounded bg-gray-700 px-4 py-2 text-white hover:bg-gray-600"
            disabled={loading}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="flex items-center justify-center rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-800"
            disabled={loading}
          >
            {loading ? <ClipLoader size={20} color={'#ffffff'} /> : 'Confirm'}
          </button>
        </div>
      </div>
    </div>
  );
};

export const VoteModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  proposal,
  selectedOption,
  onConfirm,
  walletAddress,
}) => {
  const [tokenIds, setTokenIds] = useState<string[]>([]);
  const [availableNFTs, setAvailableNFTs] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    loading: queryLoading,
    error,
    data,
  } = useQuery(GET_OWNED_AND_DELEGATED_NFTS, {
    variables: { walletAddress },
    skip: !isOpen,
  });

  useEffect(() => {
    if (!isOpen) {
      setTokenIds([]);
      setSliderValue(0);
    } else if (data) {
      // Filter available NFTs based on used NFTs in the proposal and delegation status
      let filteredNFTs = data.nftsOwnedAndDelegated;
      if (proposal && proposal.votes) {
        const usedTokenIds = proposal.votes.flatMap((vote) =>
          vote.member.walletAddress === walletAddress ? vote.nfts.map((nft) => nft.tokenID) : [],
        );
        filteredNFTs = filteredNFTs.filter(
          (nft: any) =>
            !usedTokenIds.includes(nft.tokenID) &&
            !nft.delegations.some((d: any) => d.isActive && d.delegatee.walletAddress !== walletAddress),
        );
      } else {
        filteredNFTs = filteredNFTs.filter(
          (nft: any) => !nft.delegations.some((d: any) => d.isActive && d.delegatee.walletAddress !== walletAddress),
        );
      }
      setAvailableNFTs(filteredNFTs);
    }
  }, [isOpen, data, proposal, walletAddress]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    const eligibleNFTs = availableNFTs.filter(
      (nft) => !nft.aquiredAt || parseISO(nft.aquiredAt) <= subDays(new Date(), 7),
    );

    setSliderValue(newValue);
    const newTokenIds = eligibleNFTs.slice(0, newValue).map((nft) => nft.tokenID);
    setTokenIds(newTokenIds);
  };

  const handleCheckboxChange = (tokenId: string) => {
    const eligibleNFTs = availableNFTs.filter(
      (nft) => !nft.aquiredAt || parseISO(nft.aquiredAt) <= subDays(new Date(), 7),
    );

    if (eligibleNFTs.some((nft) => nft.tokenID === tokenId)) {
      setTokenIds((prevTokenIds) => {
        let newTokenIds;
        if (prevTokenIds.includes(tokenId)) {
          // Remove tokenId
          newTokenIds = prevTokenIds.filter((id) => id !== tokenId);
        } else {
          // Add tokenId
          newTokenIds = [...prevTokenIds, tokenId];
        }

        // Update sliderValue based on the number of selected eligible NFTs
        const newSliderValue = newTokenIds.filter((id) => eligibleNFTs.some((nft) => nft.tokenID === id)).length;
        setSliderValue(newSliderValue);

        return newTokenIds;
      });
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = '/default.svg';
  };

  const handleConfirmVote = () => {
    const eligibleTokenIds = tokenIds.filter((id) => {
      const nft = availableNFTs.find((nft) => nft.tokenID === id);
      return nft && (!nft.aquiredAt || parseISO(nft.aquiredAt) <= subDays(new Date(), 7));
    });

    if (eligibleTokenIds.length === 0) {
      toast.info('Please select at least one NFT to vote.');
      return;
    }

    setConfirmModalOpen(true);
  };

  const handleFinalConfirmVote = async () => {
    setLoading(true);
    try {
      await onConfirm(tokenIds);
      setConfirmModalOpen(false);
      onClose();
    } catch (err) {
      toast.error('Error submitting vote. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  const now = new Date();
  const sevenDaysAgo = subDays(now, 7);
  const eligibleNFTs = availableNFTs.filter((nft) => !nft.aquiredAt || parseISO(nft.aquiredAt) <= sevenDaysAgo);

  const votePower = tokenIds.length;
  const selectedNFTs = availableNFTs.filter((nft) => tokenIds.includes(nft.tokenID));

  const formatAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(-4)}`;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-70">
      <div className="relative max-h-[95vh] w-11/12 overflow-y-auto rounded bg-gray-900 p-5 shadow-lg md:w-6/12">
        <div className="flex items-center justify-between">
          <h3 className="text-sm font-bold text-white">{proposal.title}</h3>
          <button className="text-lg text-white" onClick={onClose}>
            &times;
          </button>
        </div>
        <TextWithLinks textFromDatabase={proposal.description} />

        <h4 className="text-md mb-2 mt-4 text-gray-100">Selected Option</h4>
        <p className="text-xs font-semibold text-gray-100">{selectedOption.title}</p>
        <p className="text-xs text-gray-500">{selectedOption.description}</p>

        {queryLoading ? (
          <p className="text-gray-400">Loading NFTs...</p>
        ) : error ? (
          <p className="text-red-500">Error loading NFTs: {error.message}</p>
        ) : availableNFTs.length === 0 ? (
          <p className="pt-2 text-sm text-orange-300">You have no available NFTs to vote.</p>
        ) : (
          <>
            <div className="mt-4">
              <label className="block text-xs text-white">Select NFTs to vote:</label>
              <input
                type="range"
                min="0"
                max={eligibleNFTs.length}
                value={sliderValue}
                onChange={handleSliderChange}
                className="w-full"
              />
              <div className="flex justify-between text-xs text-gray-400">
                <span>0</span>
                <span>{sliderValue}</span>
                <span>{eligibleNFTs.length}</span>
              </div>
            </div>

            <ul className="mt-4 max-h-48 space-y-2 overflow-y-auto text-xs">
              {availableNFTs.map((nft: any, index: number) => {
                const acquiredDate = nft.aquiredAt ? parseISO(nft.aquiredAt) : null;
                const isEligible = !acquiredDate || acquiredDate <= sevenDaysAgo;
                const timeRemaining = acquiredDate
                  ? formatDistanceToNow(addDays(acquiredDate, 7), { addSuffix: true })
                  : null;
                return (
                  <li
                    key={nft.tokenID}
                    className={`flex items-center justify-between py-2 ${
                      index === availableNFTs.length - 1 ? 'border-none' : 'border-b border-gray-700'
                    }`}
                  >
                    <div className="flex items-center space-x-4">
                      <img
                        src={`/pfps/${nft.tokenID}.png`}
                        alt={`NFT ${nft.tokenID}`}
                        className="h-12 w-12 rounded-full"
                        onError={handleImageError}
                      />
                      <div>
                        <p className="text-gray-300">Punk Armada # {nft.tokenID}</p>
                        {acquiredDate && !isEligible && (
                          <p className="text-xs text-red-500">Eligible {timeRemaining}</p>
                        )}
                        {nft.delegations.some((d: { isActive: any }) => d.isActive) && (
                          <p className="text-green-400">
                            Delegated from:{' '}
                            <a
                              href={`https://etherscan.io/address/${
                                nft.delegations.find((d: { isActive: any }) => d.isActive).delegator.walletAddress
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-green-400"
                            >
                              {formatAddress(
                                nft.delegations.find((d: { isActive: any }) => d.isActive).delegator.walletAddress,
                              )}
                            </a>
                          </p>
                        )}
                      </div>
                    </div>
                    <input
                      type="checkbox"
                      checked={tokenIds.includes(nft.tokenID)}
                      onChange={() => handleCheckboxChange(nft.tokenID)}
                      className="ml-auto mr-4"
                      disabled={!isEligible}
                    />
                  </li>
                );
              })}
            </ul>
          </>
        )}

        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={onClose} className="rounded bg-gray-700 px-4 py-2 text-white hover:bg-gray-600">
            Cancel
          </button>
          <button
            onClick={handleConfirmVote}
            className={`rounded px-4 py-2 text-white ${
              availableNFTs.length === 0 ? 'cursor-not-allowed bg-gray-600' : 'bg-blue-600 hover:bg-blue-800'
            }`}
            disabled={availableNFTs.length === 0}
          >
            Confirm
          </button>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        tokenIds={tokenIds}
        votePower={votePower}
        selectedNFTs={selectedNFTs}
        onConfirm={handleFinalConfirmVote}
        handleCheckboxChange={handleCheckboxChange}
        loading={loading}
      />
    </div>
  );
};

export default VoteModal;
