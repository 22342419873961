import React, { FormEvent, useMemo, useRef, useState } from 'react';

import axios from 'axios';
import { toast } from 'react-toastify';

import { gql, useMutation } from '@apollo/client';
import { getNextEnvironment } from '@shared/next/env';

const CREATE_PROPOSAL_MUTATION = gql`
  mutation CreateProposal($input: CreateProposalInput!) {
    createProposal(input: $input) {
      id
      title
      description
      durationInDays
      status
      minimumApprovalPercentage
      quorumPercentage
      voteOptions {
        id
        title
        description
      }
      author {
        walletAddress
      }
    }
  }
`;

export const CreateProposalForm = ({ refetchProposals }) => {
  const environment = useMemo(() => getNextEnvironment(), []);

  const [voteOptions, setVoteOptions] = useState([
    { title: '', description: '' },
    { title: '', description: '' },
  ]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [createProposal, { loading, error }] = useMutation(CREATE_PROPOSAL_MUTATION);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleVoteOptionChange = (index, field, value) => {
    const newVoteOptions = [...voteOptions];
    newVoteOptions[index][field] = value;
    setVoteOptions(newVoteOptions);
  };

  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'audio/mpeg', 'audio/mp3'];

  const isFileAlreadySelected = (file: File) => {
    return selectedFiles.some(
      (selectedFile) =>
        selectedFile.name === file.name &&
        selectedFile.size === file.size &&
        selectedFile.lastModified === file.lastModified,
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      // Validate allowed file types
      const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

      if (validFiles.length !== newFiles.length) {
        toast.error('Some files have unsupported formats and were not added.');
      }

      // Filter out duplicate files
      const filesToAdd = validFiles.filter((file) => !isFileAlreadySelected(file));

      if (filesToAdd.length !== validFiles.length) {
        toast.warn('Some files were already selected and were not added again.');
      }

      if (filesToAdd.length > 0) {
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filesToAdd]);
      }

      // Reset the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const title = form.elements.namedItem('title') as HTMLInputElement;
    const description = form.elements.namedItem('description') as HTMLInputElement;
    const durationInDays = form.elements.namedItem('durationInDays') as HTMLInputElement;

    if (title && description && durationInDays) {
      try {
        // Create the proposal
        const response = await createProposal({
          variables: {
            input: {
              title: title.value,
              description: description.value,
              durationInDays: parseInt(durationInDays.value),
              voteOptions: voteOptions.filter((opt) => opt.title),
              minimumApprovalPercentage: minimumApprovalPercentage ? parseFloat(minimumApprovalPercentage) : null,
              quorumPercentage: quorumPercentage ? parseFloat(quorumPercentage) : null,
            },
          },
        });

        const proposalId = response.data.createProposal.id;

        // Upload files if any are selected
        if (selectedFiles && selectedFiles.length > 0) {
          const formData = new FormData();
          selectedFiles.forEach((file) => {
            formData.append('files', file);
          });

          // Make the file upload request to the backend
          await axios.post(
            environment.graphqlServerUrl.replace('/graphql', '') + `/upload/multiple/${proposalId}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );
        }

        toast.success('Proposal created successfully');
        refetchProposals(); // Refresh proposals after creation

        // Reset form fields and selected files
        form.reset();
        setVoteOptions([
          { title: '', description: '' },
          { title: '', description: '' },
        ]);
        setSelectedFiles([]);
      } catch (error) {
        console.error('Error creating proposal or uploading files:', error);
        toast.error('Error creating proposal or uploading files');
      }
    }
  };
  const [minimumApprovalPercentage, setMinimumApprovalPercentage] = useState('');
  const [quorumPercentage, setQuorumPercentage] = useState('');

  return (
    <div className="mx-auto">
      <form onSubmit={handleSubmit} className="mx-auto space-y-4 rounded bg-gray-800 p-4 text-xs text-white">
        <input
          type="text"
          name="title"
          required
          placeholder="Title"
          className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <textarea
          name="description"
          required
          placeholder="Description"
          className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={4}
        />
        <input
          type="number"
          name="durationInDays"
          placeholder="Duration in Days"
          required
          className="w-full rounded bg-gray-700 p-2 text-white focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="number"
          name="minimumApprovalPercentage"
          placeholder="Minimum Approval Percentage (optional)"
          value={minimumApprovalPercentage}
          onChange={(e) => setMinimumApprovalPercentage(e.target.value)}
          min="1"
          max="100"
          step="0.01"
          className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="number"
          name="quorumPercentage"
          placeholder="Minimum Quorum Percentage (optional)"
          value={quorumPercentage}
          onChange={(e) => setQuorumPercentage(e.target.value)}
          min="1"
          max="100"
          step="0.01"
          className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {voteOptions.map((option, index) => (
          <div key={index} className="space-y-1">
            <input
              type="text"
              placeholder={`Option ${index + 1} Title`}
              value={option.title}
              onChange={(e) => handleVoteOptionChange(index, 'title', e.target.value)}
              className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required={index === 0}
            />
            <input
              type="text"
              placeholder={`Option ${index + 1} Description`}
              value={option.description}
              onChange={(e) => handleVoteOptionChange(index, 'description', e.target.value)}
              className="w-full rounded bg-gray-700 p-2 text-white placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required={index === 1}
            />
          </div>
        ))}
        <button
          className="mx-auto"
          type="button"
          onClick={() => setVoteOptions([...voteOptions, { title: '', description: '' }])}
          disabled={voteOptions.length >= 10}
        >
          Add Another Option
        </button>

        {/* File input field */}
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
          className="w-full rounded bg-gray-700 p-2 text-white focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        {/* Display selected files */}
        {selectedFiles.length > 0 && (
          <div className="mt-2">
            <h3>Selected Files:</h3>
            <ul className="mt-2">
              {selectedFiles.map((file, index) => (
                <li key={index} className="mt-2 flex items-center justify-between">
                  <span>{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        <button
          type="submit"
          disabled={loading}
          className="w-full rounded bg-blue-600 p-3 text-white hover:bg-blue-700 disabled:bg-gray-600"
        >
          {loading ? 'Creating Proposal...' : 'Create Proposal'}
        </button>
        {error && <p className="text-center text-red-400">{error.message}</p>}
      </form>
    </div>
  );
};
