import React from 'react';

import { formatWalletAddress } from '@shared/web3/utils';

function VoteDetails({ optionId, proposalData }) {
  const getVotePercentage = (optionId, nftCount) => {
    const totalNftsForOption =
      proposalData?.proposal?.votes
        ?.filter((vote) => vote.voteOption.id === optionId && vote.nfts.length > 0)
        .reduce((acc, vote) => acc + vote.nfts.length, 0) || 0;
    return totalNftsForOption > 0 ? ((nftCount / totalNftsForOption) * 100).toFixed(2) : 0;
  };

  return (
    <div className="mt-4 rounded bg-gray-800 p-4">
      <h4 className="text-sm font-bold text-white">Vote Details</h4>
      {proposalData?.proposal?.votes
        ?.filter((vote) => vote.voteOption.id === optionId && vote.nfts.length > 0)
        .map((vote) => (
          <div key={vote.id} className="mt-2 border-b border-gray-700 p-2">
            <p className="text-xs text-gray-400">
              Wallet:{' '}
              <a
                href={`https://etherscan.io/address/${vote.member.walletAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                {formatWalletAddress(vote.member.walletAddress)}
              </a>
            </p>
            <p className="text-xs text-gray-400">NFTs Used:</p>
            <div className="flex flex-wrap space-x-2">
              {vote.nfts.map((nft) => (
                <div key={nft.tokenID} className="text-xs text-gray-400">
                  {nft.tokenID}
                </div>
              ))}
            </div>
            <p className="mt-2 text-xs text-green-400">
              Vote Power: {vote.nfts.length} ({getVotePercentage(optionId, vote.nfts.length)}%)
            </p>
          </div>
        ))}
    </div>
  );
}

export default VoteDetails;
