import React from 'react';

import { formatDistanceToNow } from 'date-fns';

import ProposalDetails from './ProposalDetails';
import TextWithLinks from './TextWithLinks';

type ProposalStatus = 'IN_PROGRESS' | 'CLOSED' | 'ACCEPTED' | 'REJECTED' | string;

interface Media {
  id: string;
  filename: string;
  originalName: string;
  mimeType: string;
  path: string;
}

interface VoteOption {
  id: string;
  title: string;
  description: string;
}

interface Proposal {
  id: string;
  title: string;
  description: string;
  status: ProposalStatus;
  createdAt: string; // Can be Date if you're handling it as a date object
  durationInDays: number;
  minimumApprovalPercentage?: number;
  quorumPercentage?: number;
  media?: Media[];
  voteOptions: VoteOption[];
  winningVoteOptionId?: string;
}

interface ProposalItemProps {
  proposal: Proposal;
  expandedId: string | null;
  toggleExpand: (id: string) => void;
  address?: string | null;
  handleVoteClick?: (proposal: Proposal, option: VoteOption) => void;
  expandedOptionId?: string | null;
  toggleOptionExpand?: (optionId: string) => void;
  proposalData?: any;
  proposalLoading?: boolean;
  refetchProposal: (data: any) => void;
}

function getColorForStatus(status: ProposalStatus): string {
  switch (status) {
    case 'IN_PROGRESS':
      return 'text-yellow-400';
    case 'CLOSED':
      return 'text-green-500';
    case 'ACCEPTED':
      return 'text-green-500';
    case 'REJECTED':
      return 'text-red-500';
    default:
      return 'text-gray-500';
  }
}

const ProposalItem: React.FC<ProposalItemProps> = ({ proposal, expandedId, toggleExpand, ...props }) => {
  const deadline = new Date(proposal.createdAt);
  deadline.setDate(deadline.getDate() + proposal.durationInDays);
  const timeLeft = formatDistanceToNow(deadline, { addSuffix: true });

  return (
    <li key={proposal.id} className="rounded bg-gray-800 p-4">
      <div className="flex items-start justify-between">
        <div className="flex flex-col space-y-2">
          <h2 className="text-md font-semibold text-white">{proposal.title}</h2>
          {proposal.minimumApprovalPercentage && proposal.minimumApprovalPercentage > 0 && (
            <h2
              className="text-md font-semibold text-blue-400"
              title="Minimum percentage of approval votes required for this proposal to pass."
            >
              Min Approval: {proposal.minimumApprovalPercentage}%
            </h2>
          )}
          {proposal.quorumPercentage && proposal.quorumPercentage > 0 && (
            <h2
              className="text-md font-semibold text-blue-500"
              title="Minimum percentage of total eligible voters required for the proposal to be valid."
            >
              Min Quorum: {proposal.quorumPercentage}%
            </h2>
          )}
          <TextWithLinks textFromDatabase={proposal.description} />
        </div>
        <div
          className="flex cursor-pointer flex-col space-y-2 text-right text-xs"
          onClick={() => toggleExpand(proposal.id)}
        >
          <p className="text-gray-400">
            <span className={`font-semibold ${getColorForStatus(proposal.status)}`}>{proposal.status}</span>
          </p>
          <p className="text-gray-400">
            {proposal.status === 'IN_PROGRESS' ? `Closes ${timeLeft}` : `Closed on: ${deadline.toLocaleDateString()}`}
          </p>
          <p className="text-green-500">More</p>
        </div>
      </div>
      {expandedId === proposal.id && <ProposalDetails proposal={proposal} {...props} />}
    </li>
  );
};

export default ProposalItem;
