import React from 'react';

import { getNextEnvironment } from '@shared/next/env';

const environment = getNextEnvironment();

function Attachments({ media }) {
  return (
    <div className="rounded bg-gray-700 p-4">
      <h2 className="text-white">Attachments:</h2>
      <ul className="ml-2 mt-2">
        {media.map((mediaItem) => (
          <li className="mt-1" key={mediaItem.id}>
            <a
              href={environment.graphqlServerUrl.replace('/graphql', '') + mediaItem.path}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              {mediaItem.originalName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Attachments;
