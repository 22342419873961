// ProposalDetails.tsx

import React, { useMemo, useRef, useState } from 'react';

import axios from 'axios';
import gql from 'graphql-tag';
import Image from 'next/image';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { useAuthContext } from '@shared/next/auth';
import { getNextEnvironment } from '@shared/next/env';

import Attachments from './Attachments';
import VoteOptionItem from './VoteOptionItem';

const DELETE_PROPOSAL_MUTATION = gql`
  mutation DeleteProposal($input: DeleteProposalInput!) {
    deleteProposal(input: $input)
  }
`;

const CREATE_PROPOSAL_MUTATION = gql`
  mutation CreateProposal($input: CreateProposalInput!) {
    createProposal(input: $input) {
      id
      title
      description
      durationInDays
      status
      minimumApprovalPercentage
      quorumPercentage
      voteOptions {
        id
        title
        description
      }
      author {
        walletAddress
      }
    }
  }
`;

interface Media {
  id: string;
  filename: string;
  originalName: string;
  mimeType: string;
  path: string;
}

interface VoteOption {
  id: string;
  title: string;
  description: string;
}

type ProposalStatus = 'IN_PROGRESS' | 'CLOSED' | 'ACCEPTED' | 'REJECTED' | string;

interface Proposal {
  id: string;
  title: string;
  description: string;
  status: ProposalStatus;
  createdAt: string;
  durationInDays: number;
  minimumApprovalPercentage?: number;
  quorumPercentage?: number;
  media?: Media[];
  voteOptions: VoteOption[];
  winningVoteOptionId?: string;
}

interface ProposalDetailsProps {
  proposal: Proposal;
  address?: string | null;
  handleVoteClick?: (proposal: Proposal, option: VoteOption) => void;
  expandedOptionId?: string | null;
  toggleOptionExpand?: (optionId: string) => void;
  proposalData?: any;
  proposalLoading?: boolean;
  refetchProposal?: (data: any) => void; // Function to refetch
}

const ProposalDetails: React.FC<ProposalDetailsProps> = ({
  proposal,
  address,
  handleVoteClick,
  expandedOptionId,
  toggleOptionExpand,
  proposalData,
  proposalLoading,
  refetchProposal,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteProposal] = useMutation(DELETE_PROPOSAL_MUTATION);

  const handleDeleteProposal = async () => {
    try {
      await deleteProposal({
        variables: {
          input: {
            id: proposal.id,
          },
        },
      });
      toast.success('Proposal deleted successfully.');
      location.reload();
    } catch (error) {
      console.error('Error deleting proposal:', error);
      toast.error('Error deleting proposal.');
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const environment = useMemo(() => getNextEnvironment(), []);

  const allowedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'audio/mpeg', 'audio/mp3'];

  const isFileAlreadySelected = (file: File) => {
    return selectedFiles.some(
      (selectedFile) =>
        selectedFile.name === file.name &&
        selectedFile.size === file.size &&
        selectedFile.lastModified === file.lastModified,
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      // Validate allowed file types
      const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

      if (validFiles.length !== newFiles.length) {
        toast.error('Some files have unsupported formats and were not added.');
      }

      // Filter out duplicate files
      const filesToAdd = validFiles.filter((file) => !isFileAlreadySelected(file));

      if (filesToAdd.length !== validFiles.length) {
        toast.warn('Some files were already selected and were not added again.');
      }

      if (filesToAdd.length > 0) {
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...filesToAdd]);
      }

      // Reset the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((_, i) => i !== index));
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length === 0) {
      toast.warn('No files selected for upload.');
      return;
    }

    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });

      // Perform the file upload request
      await axios.post(
        environment.graphqlServerUrl.replace('/graphql', '') + `/upload/multiple/${proposal.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      toast.success('Files uploaded successfully.');

      // Update the list of attachments
      if (refetchProposal) {
        refetchProposal({ id: proposal.id });
      }

      // Clear selected files
      setSelectedFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
      toast.error('Error uploading files.');
    }
  };
  const { authIsAdmin, hasAuthContext } = useAuthContext();
  const [createProposal, { loading: cloning }] = useMutation(CREATE_PROPOSAL_MUTATION);
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false); // New state for the clone modal

  const handleCloneProposal = async () => {
    try {
      // Prepare data to create the new proposal
      const newTitle = `[v2] ${proposal.title}`;
      const input = {
        title: newTitle,
        description: proposal.description,
        durationInDays: proposal.durationInDays,
        minimumApprovalPercentage: proposal.minimumApprovalPercentage,
        quorumPercentage: proposal.quorumPercentage,
        voteOptions: proposal.voteOptions.map((option) => ({
          title: option.title,
          description: option.description,
        })),
      };

      // Call the mutation to create the proposal
      const response = await createProposal({
        variables: {
          input,
        },
      });

      toast.success('Proposal cloned successfully.');
      // Redirect to the new proposal or update the list
      if (refetchProposal) {
        refetchProposal({ id: response.data.createProposal.id });
      }
    } catch (error) {
      console.error('Error cloning proposal:', error);
      toast.error('Error cloning proposal.');
    } finally {
      setIsCloneModalOpen(false);
    }
  };

  return (
    <div className="mt-2 space-y-4">
      {proposal.media && proposal.media.length > 0 && <Attachments media={proposal.media} />}
      <Image className="pixelated w-full" src={'/vote.png'} height={100} width={100} alt="vote"></Image>
      {proposal.voteOptions.map((option) => (
        <VoteOptionItem
          key={option.id}
          option={option}
          proposal={proposal}
          address={address}
          handleVoteClick={handleVoteClick}
          expandedOptionId={expandedOptionId}
          toggleOptionExpand={toggleOptionExpand}
          proposalData={proposalData}
          proposalLoading={proposalLoading}
        />
      ))}

      {/* Admin section */}
      {authIsAdmin === 'admin' && hasAuthContext && (
        <>
          {proposal.status === 'REJECTED' && (
            <>
              <button
                type="button"
                onClick={() => setIsCloneModalOpen(true)}
                className="mt-4 w-full rounded bg-green-600 p-3 text-white hover:bg-green-700"
              >
                Clone Proposal
              </button>

              {/* Confirmation Modal */}
              {isCloneModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                  {/* Semi-transparent background */}
                  <div className="fixed inset-0 bg-black opacity-50" onClick={() => setIsCloneModalOpen(false)}></div>

                  {/* Modal content */}
                  <div className="relative z-50 mx-auto max-w-sm rounded bg-white p-6">
                    <h2 className="text-lg font-bold">Confirm Cloning</h2>
                    <p className="mt-2 text-sm text-gray-500">
                      Are you sure you want to clone this proposal? A new proposal with the same details will be
                      created.
                    </p>

                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        type="button"
                        onClick={() => setIsCloneModalOpen(false)}
                        className="rounded bg-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-400"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={handleCloneProposal}
                        className="rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700"
                        disabled={cloning}
                      >
                        {cloning ? 'Cloning...' : 'Clone'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="mt-4">
            <h3 className="text-lg font-semibold">Attach Files</h3>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              className="w-full rounded bg-gray-700 p-2 text-white focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {selectedFiles.length > 0 && (
              <div className="mt-2">
                <h4>Selected Files:</h4>
                <ul className="mt-2">
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="mt-2 flex items-center justify-between">
                      <span>{file.name}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <button
                  type="button"
                  onClick={handleFileUpload}
                  className="mt-4 w-full rounded bg-blue-600 p-3 text-white hover:bg-blue-700"
                >
                  Upload Files
                </button>
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={() => setIsDeleteModalOpen(true)}
            className="mt-4 w-full rounded bg-red-600 p-3 text-white hover:bg-red-700"
          >
            Delete Proposal
          </button>

          {/* Confirmation Modal for Deletion */}
          {isDeleteModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              {/* Semi-transparent background */}
              <div className="fixed inset-0 bg-black opacity-50" onClick={() => setIsDeleteModalOpen(false)}></div>

              {/* Modal content */}
              <div className="relative z-50 mx-auto max-w-sm rounded bg-white p-6">
                <h2 className="text-lg font-bold">Confirm Deletion</h2>
                <p className="mt-2 text-sm text-gray-500">
                  Are you sure you want to delete this proposal? This action cannot be undone.
                </p>

                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setIsDeleteModalOpen(false)}
                    className="rounded bg-gray-300 px-4 py-2 text-gray-700 hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleDeleteProposal}
                    className="rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProposalDetails;
