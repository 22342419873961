import React from 'react';

import VoteDetails from './VoteDetails';

import { ProposalStatus } from '@.generated/model';

function VoteOptionItem({
  option,
  proposal,
  address,
  handleVoteClick,
  expandedOptionId,
  toggleOptionExpand,
  proposalData,
  proposalLoading,
}) {
  const calculateVotePower = (optionId) => {
    const totalVotePower =
      proposalData?.proposal?.votes
        .filter((vote) => vote.nfts.length > 0)
        .reduce((acc, vote) => acc + vote.nfts.length, 0) || 0;
    const optionVotePower =
      proposalData?.proposal?.votes
        .filter((vote) => vote.voteOption.id === optionId && vote.nfts.length > 0)
        .reduce((acc, vote) => acc + vote.nfts.length, 0) || 0;
    return totalVotePower > 0 ? ((optionVotePower / totalVotePower) * 100).toFixed(2) : 0;
  };

  const getVoteCount = (optionId) => {
    return (
      proposalData?.proposal?.votes?.filter((vote) => vote.voteOption.id === optionId && vote.nfts.length > 0).length ||
      0
    );
  };

  const getNftCount = (optionId) => {
    return (
      proposalData?.proposal?.votes
        ?.filter((vote) => vote.voteOption.id === optionId && vote.nfts.length > 0)
        .reduce((acc, vote) => acc + vote.nfts.length, 0) || 0
    );
  };

  return (
    <div
      className={`rounded bg-gray-700 p-4 ${
        (proposal.status === 'CLOSED' || proposal.status === 'ACCEPTED') &&
        Number(proposal.winningVoteOptionId) === Number(option.id)
          ? 'bg-green-700'
          : ''
      }`}
    >
      <div className="flex items-start justify-between">
        <div className="flex flex-col">
          <p className="text-sm text-white">{option.title}</p>
          <p className="text-xs text-gray-400">{option.description}</p>
        </div>
        <div className="flex flex-col text-right text-xs">
          <p className="text-gray-400">{getVoteCount(option.id)} Votes </p>
          <p className="text-gray-400">
            {getNftCount(option.id)} NFTs ({calculateVotePower(option.id)}% vote power)
          </p>
          {address && proposal.status === ProposalStatus.InProgress && (
            <button
              onClick={() => handleVoteClick(proposal, option)}
              className={`mt-2 w-40 self-end rounded px-4 py-2 font-bold text-white ${
                proposalLoading ? 'cursor-not-allowed bg-gray-500' : 'bg-blue-500 hover:bg-blue-700'
              }`}
              disabled={proposalLoading}
            >
              {'Vote'}
            </button>
          )}
          <button
            onClick={() => toggleOptionExpand(option.id)}
            className="mt-2 w-40 self-end rounded bg-gray-500 px-4 py-2 font-bold text-white hover:bg-gray-600"
          >
            {expandedOptionId === option.id ? 'Hide Details' : 'Show Details'}
          </button>
        </div>
      </div>
      {expandedOptionId === option.id && <VoteDetails optionId={option.id} proposalData={proposalData} />}
    </div>
  );
}

export default VoteOptionItem;
